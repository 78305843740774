<template>
    <modal ref="modalLiquidar" titulo="Liquidar" tamano="modal-md" no-aceptar adicional="Liquidar" @adicional="liquidar">
        <div class="row mx-0">
            <div class="col px-0">
                <div class="row mx-0 px-2">
                    <div class="col-auto">
                        <p class="text-general f-14">
                            Deudores
                        </p>
                        <div class="d-middle text-general">
                            <i class="icon-cliente f-20" />
                            <span class="f-600 f-22 ml-2">
                                {{ totalDeudores }}
                            </span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <p class="text-general f-14">
                            Deuda
                        </p>
                        <div class="d-middle text-general">
                            <i class="icon-pedidos f-20" />
                            <span class="f-600 f-22 ml-2">
                                {{ convertMoney(totalDeuda) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row mx-1 px-2 my-3">
                    <p class="f-14 text-general">
                        Para liquidar, indica la fecha en la que descontará de nómina a cada uno de los deudores.
                    </p>
                </div>
                <div class="row mx-0 px-2 mb-2">
                    <div class="col-8 pl-0">
                        <p class="text-general f-12 pl-3">
                            Fecha
                        </p>
                        <el-date-picker
                        v-model="fechaDescuento"
                        type="date"
                        size="small"
                        class="w-100"
                        placeholder="Seleccione Fecha"
                        format="dd MMMM yyyy"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        />
                    </div>
                </div>
                <div class="row mx-0 px-2 my-3">
                    <p class="text-general f-12 pl-3">
                        Observaciones
                    </p>
                    <el-input v-model="comentario" type="textarea" rows="4" placeholder="Comentario..." class="w-100" maxlength="500" />
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Nomina from '~/services/nomina/afiliados'
export default {
    data(){
        return {
            totalDeudores:0,
            totalDeuda:0,
            fechaCorte:'',
            fechaDescuento: '',
            comentario:'',
            pedidosLiquidar:[],
            tipoLiquidacion:3
        }
    },
    computed:{
        idConvenio(){
            return Number(this.$route.params.id_convenio)
        },
    },
    methods: {
        toggle(params){

            this.totalDeudores = params.deudores
            this.totalDeuda = params.deuda
            this.fechaCorte = params.fechaCorte
            this.pedidosLiquidar = params.pedidos
            this.tipoLiquidacion = params.tipoLiquidacion

            this.$refs.modalLiquidar.toggle();
        },
        async liquidar(){
            try {
                if(this.totalDeudores === 0) return this.notificacion("Advertencia","Por favor seleccione almenos un pedido para generar la liquidación",'warning')
                if(this.fechaDescuento === null || this.fechaDescuento === '') return
                const form = {
                    tipo: this.tipoLiquidacion,
                    fecha_corte: this.fechaCorte,
                    fecha_descuento: this.fechaDescuento,
                    comentario: this.comentario,
                    id_convenio: this.idConvenio,
                    pedidos:this.pedidosLiquidar

                }
                const {data} = await Nomina.liquidarPedidos(this.idConvenio,form)
                this.notificacion('Exito','Se han Liquidado los usuarios correctamente','success')
                this.limpiar()
                this.$emit('update')
                this.$refs.modalLiquidar.toggle();

            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.totalDeudores = 0,
            this.totalDeuda = 0,
            this.fechaCorte = '',
            this.fechaDescuento =  '',
            this.comentario = '',
            this.pedidosLiquidar = [],
            this.tipoLiquidacion = 3
        }


    }
}
</script>
